import React from 'react'
import { Link } from "gatsby"
import logo from '../images/nuerex_logo.png'
import Sticky from 'react-stickynode';
import Scrollspy from 'react-scrollspy-highlight'

export default class Header extends React.Component {
    constructor () {
        super()
        this.state = {
            showMenuClass: '',
        }
        this.toggleMenuMobile = this.toggleMenuMobile.bind(this);
    }

    toggleMenuMobile () {
        if (this.state.showMenuClass === '') {
            this.setState({
                showMenuClass: 'show'
            })
        }
        else{
            this.setState({
                showMenuClass: ''
            })
        }     
      }
    render(){
        return(
            <Sticky enabled={true} top={0} bottomBoundary={0} innerZ={999}>
            <section id="header" className="section header">
                <div className="container">
                    <div className="logo">
                        <Link to="/"><img src={logo} alt="Nuerex Logo" /></Link>
                    </div> 
                        <span className="mobile-menu-open" onClick={this.toggleMenuMobile.bind(this)}><i></i></span>
                        <div className={"mobile-navbar-background " + this.state.showMenuClass}>
                        <Scrollspy items={ ['p_business_consulting', 'p_technology_services', 'p_digital_solutions', 'p_industry_expetise', 'p_blog', 'p_about', 'p_contact_us'] } currentClassName="is-current">
                            <span className="mobile-menu-close" onClick={this.toggleMenuMobile.bind(this)}><i></i></span>
                                <div className="inner">
                                    <li className="business-consulting"><Link to="/business-consulting">Business Consulting</Link></li>
                                    <li><Link to="/technology-services">Technology</Link></li>
                                    <li><Link to="/digital-consulting-services">Digital</Link></li>
                                    <li><Link to="/industry-expertise">Industries</Link></li>
                                    <li><Link to="/blog">Blog</Link></li>
                                    <li><Link to="/about-us">About</Link></li>
                                    <li className="contact-button"><Link to="/contact-us">Contact Us</Link></li>
                                </div>
                            </Scrollspy>
                        </div>
                        
                    <div className="navigation">
                    <Scrollspy items={ ['p_business_consulting', 'p_technology_services', 'p_digital_solutions', 'p_industry_expetise', 'p_blog', 'p_about', 'p_contact_us'] } currentClassName="is-current">
                            <li><Link to="/business-consulting">Business Consulting</Link>
                            <div className="columns submenu">
                                <div className="column is-6 is-left business-consulting">
                                    <span className="icon"></span>
                                    <h6>Business Consulting</h6>
                                    <p>Together we can optimize and transform your business, 
                                        all the way from the people to the processes.</p>
                                </div>
                                <ul className="column is-6 is-right">
                                    <li><Link to="/business-consulting/business-strategy">Business Strategy</Link></li>
                                    <li><Link to="/business-consulting/operational-and-process-excellence">Process and Operational Excellence</Link></li>
                                    <li><Link to="/business-consulting/people-and-change-consulting">People and Change</Link></li>
                                    <li><Link to="/business-consulting/enterprise-program-management">Enterprise Program Management</Link></li>
                                </ul>
                            </div>
                            </li>
                            <li><Link to="/technology-services">Technology</Link>
                            <div className="columns submenu big">
                                <div className="column is-6 is-left technology-services">
                                    <span className="icon"></span>
                                    <h6>Technology Services</h6>
                                    <p>Together we outline, create and deliver technology solutions to fix problems.</p>
                                </div>
                                <div className="columns is-6 is-right">
                                    <ul className="column is-6 is-left">
                                        <li><Link to="/technology-services/data-and-analytics">Data and Analytics</Link></li>
                                        <li><Link to="/technology-services/modern-software-delivery">Modern Software Delivery</Link></li>
                                        <li><Link to="/technology-services/enterprise-collaboration">Enterprise Collaboration</Link></li>
                                        <li><Link to="/technology-services/mobile-app-development">Mobile App Development</Link></li>
                                        <li><Link to="/technology-services/it-strategy">IT Strategy</Link></li>
                                    </ul>
                                    <ul className="column is-6 is-right">
                                        <li><Link to="/technology-services/devops">Devops</Link></li>
                                        <li><Link to="/technology-services/outsourcing">Outsourcing</Link></li>
                                        <li><Link to="/technology-services/enterprise-applications-and-solutions">Enterprise Applications and Solutions</Link></li>
                                        <li><Link to="/technology-services/cloud-computing">Cloud Computing</Link></li>
                                    </ul>
                                </div>
                                
                            </div>
                            </li>
                            <li><Link to="/digital-consulting-services">Digital</Link>
                            <div className="columns submenu">
                                <div className="column is-6 is-left digital-solutions">
                                    <span className="icon"></span>
                                    <h6>Digital Solutions</h6>
                                    <p>Together we can plan your digital strategy and make your marketing plan a success.</p>
                                </div>
                                <ul className="column is-6 is-right">
                                    <li><Link to="/digital-consulting-services/digital-strategy">Digital Strategy</Link></li>
                                    <li><Link to="/digital-consulting-services/digital-technology-services">Digital Technology Services</Link></li>
                                    <li><Link to="/digital-consulting-services/test-automation-and-devops-success">Test automation & DevOps Success</Link></li>
                                    <li><Link to="/digital-consulting-services/customer-experience-and-design">Customer Experience and Design</Link></li>
                                </ul>
                            </div>
                            </li>
                            <li><Link to="/industry-expertise">Industries</Link></li>
                            <li><Link to="/blog">Blog</Link></li>
                            <li><Link to="/about-us">About</Link></li>
                            <li className="contact-button"><Link to="/contact-us">Contact Us</Link></li>
                        </Scrollspy>
                    </div>   
                </div>        
            </section>
        </Sticky>
        )
    }
}
