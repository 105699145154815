import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import title_image from '../../images/titles/digital_strategy.jpg'
import person from '../../images/person.png'
import digital_strategy from '../../images/pages/digital_strategy.png'
import digital_strategy_approach from '../../images/pages/digital_strategy_approach.jpg'

const pageTitle = "Digital Strategy";
const pageSlug = "digital-strategy";
class BusinessConsulting extends React.Component{
  render(){
    return(
      <Layout pageType="digital-consulting-services">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Together we can create a winning digital strategy that has the right 
            combination of services, products, and capabilities. That ensures you can reach your business 
            goals in the digital world.</h6>
        </div>
      </section>

      <section id="p_digital_solutions" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    Having a defined digital strategy is no longer optional; in today's market, it is 
                    a critical factor to ensure that your business succeeds.
                    </h3>
                  <p>We can guide your team to create or update strategy, regardless of its focus. 
                      Whether it's digital marketing, new technologies, or organizational transforming to 
                      serve your customers more digitally.</p>
                    <p>Want to learn how a digital strategy can impact your business?</p>
                    <Link to="/contact-us" className="button green">Contact us</Link>
                <span className="break"></span>
                <h4>Digital Strategy Continuum</h4>
                <p>We can help you plan and chart your digital course regardless of where you currently are.</p>
                <img src={digital_strategy} />
                <h4>Our Digital Approach</h4>
                <h6>We personalize our approach to match your specific situation helping you establish a plan by:</h6>
                <ul>
                    <li>Assessing multiple dimensions of your digital environment </li>
                    <li>Focusing on your organization's digital marketing ecosystem and technology infrastructure</li>
                    <li>Developing a comprehensive digital strategy and development plan</li>
                </ul>
                <img src={digital_strategy_approach} />
                <div className="contact-box">
                    <h3>Want to compete in today's digital world? Together we can get you there.</h3>  
                    <span className="break"></span>
                    <div className="button-area">
                        <Link to="/contact-us" className="button default">Contact us</Link>
                    </div>
                    </div>
                </div>
               {/* <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default BusinessConsulting
