import React from 'react'
import { Link } from "gatsby"
import logo from '../images/nuerex_logo.png'
var date = new Date();
var year = date.getFullYear();

const Footer = () => (
    <>
    <section id="footer" className="section footer">
        <div className="container">
          <div className="top-part">
            <div className="columns">
              <div className="column subscribe">
                  <Link to="/"><img src={logo} alt="Nuerex Logo" /></Link>
                  <p>Join 16,000 other people and get the latest updates on business, 
                    technology and digital to help improve your business.</p>
                  <Link to="/" className="button green-border">Subscribe</Link>
              </div>
              <div className="column menu">
                <h6 className="menu-title">Services</h6>
                  <ul>
                    <li><Link to="/business-consulting">Business Consulting</Link></li>
                    <li><Link to="/technology-services">Technology Services</Link></li>
                    <li><Link to="/digital-consulting-services">Digital Consulting</Link></li>
                    <li><Link to="/industry-expertise">Industries</Link></li>
                  </ul>
              </div>

              <div className="column menu">
                <h6 className="menu-title">Company</h6>
                  <ul>
                    <li><Link to="/about-us">About Nuerex</Link></li>
                    <li><Link to="/blog">News & Blog</Link></li>
                    <li><Link to="/contact-us">Contact</Link></li>
                  </ul>
              </div>
              
              <div className="column menu">
                <h6 className="menu-title">Follow us</h6>
                  <ul>
                    <li><Link to="/">Facebook</Link></li>
                    <li><Link to="/">Twitter</Link></li>
                    <li><Link to="/">Linkdin</Link></li>
                  </ul>
              </div>
              <div className="column menu">
                <h6 className="menu-title">Contact Us</h6>
                  <ul>
                    <li className="with-icon"><i className="address"></i><a href="https://www.google.com/maps/place/1900+Campus+Commons+Dr+%23100,+Reston,+VA+20191,+Sjedinjene+Dr%C5%BEave/@38.9443739,-77.3369641,16.25z/data=!4m5!3m4!1s0x89b64834752d32bf:0x1fddb5d96344aca1!8m2!3d38.9447206!4d-77.3367495" target="_blank">1900 Campus Commons Dr. Suite 100, Reston, VA 20191 </a></li>
                    <li className="with-icon"><i className="phone"></i><a href="tel:571-559-3800">571-559-3800</a></li>
                  </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section copyright">
        <div className="container">
              <p className="is-left">&copy; {year} Nuerex. </p>
             {/* <p className="is-right">Created by <a href="https://wellpromotion.ba" target="_blank">Well Business Promotion</a></p> */}
        </div>
      </section>
      </>
)

export default Footer;